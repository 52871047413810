import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { gsap } from 'gsap'
import logo from '../../images/kept.svg'
import menu from '../../images/menu.svg'
import arrow from '../../images/arrow.png'
import StoreContext from '~/context/StoreContext'
import './SidebarMenu.scss'

const SidebarMenu = ({ checkout }) => {
  const {
    store: { sidebarMenuOpen },
    openMenu,
  } = useContext(StoreContext)

  const sidebar = React.useRef()
  React.useEffect(() => {
    gsap.fromTo(sidebar.current, { x: '-60px' }, { x: '0', duration: 2 })
  }, [])
  return (
    <div className="sidebar-menu tablet-and-up" ref={sidebar}>
      <div className="menuItem menu">
        {checkout ? (
          <Link to="/">
            <div className="back-btn-container relative">
              <img className="back-btn" src={arrow} alt="Back Button" />
              <div className="border-gradient" />
            </div>
          </Link>
        ) : (
          <button
            className={`menuTrigger ${
              sidebarMenuOpen ? 'menuTrigger--open' : ''
            }`}
            onClick={openMenu}
          />
        )}
      </div>
      <div className="menuItem logo">
        <Link to="/">
          <img src={logo} alt="Kept Logo" />
        </Link>
      </div>
      <div className="menuItem copyright roboto-txt-sm">
        <span>Copyright 2019 ©</span>
      </div>
    </div>
  )
}

export default SidebarMenu
