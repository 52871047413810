import React, { useContext } from 'react'
import { Link } from 'gatsby'
import Cookies from 'js-cookie'
import logo from '../../images/kept.svg'
import menu from '../../images/menu.svg'
import './styles.scss'
import account from '../../images/account.png'
import cart from '../../images/Cart.png'
import StoreContext from '~/context/StoreContext'

const MobileMenu = () => {
  const {
    store: { sidebarMenuOpen },
    openCart,
    openMenu,
  } = useContext(StoreContext)
  const customer = Cookies.get('KEPT_SESS')
  return (
    <div className="mobile-menu mobile">
      <div className="menu-section menu-left">
        <button className={`menuTrigger ${sidebarMenuOpen ? 'menuTrigger--open' : ''}`} onClick={openMenu} />
        <Link to="/">
          <img className="logo" src={logo} alt="Kept Logo" />
        </Link>
      </div>
      <div className="menu-section menu-right">
        <Link to={customer ? '/account' : '/login'}>
          <img src={account} alt="account" />
        </Link>
        <img className="cart-click" src={cart} alt="cart" onClick={openCart} />
      </div>
      <div className="border-gradient" />
    </div>
  )
}

export default MobileMenu
