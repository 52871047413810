import axios from 'axios'
const serverUrl = ''
// const serverUrl = 'http://localhost:3000'

export const getPaymentIntent = async totalPrice => {
  const intent = await axios.post(`${serverUrl}/api/stripe/intent`, {
    totalPrice,
  })
  return intent
}
export const getSetupIntent = async () => {
  const intent = await axios.get(`${serverUrl}/api/stripe/set-up-intent`)
  return intent
}
export const createStripeCustomer = (intent, email, lineItems, shopify_id) => {
  return axios.post(`${serverUrl}/api/stripe/create-customer`, {
    intent,
    email,
    lineItems,
    shopify_id,
  })
}

export const createShopifyOrder = (
  values,
  customer,
  lineItems,
  shipping,
  discountApplications,
  lineItemTotalPrice,
  subtotalPrice
) => {
  return axios.post(`${serverUrl}/api/shopify/create-order`, {
    values,
    lineItems,
    customer,
    shipping,
    discountApplications,
    lineItemTotalPrice,
    subtotalPrice,
  })
}
export const calculateShopifyOrder = (
  values,
  customer,
  lineItems,
  shipping,
  discountApplications,
  lineItemTotalPrice,
  subtotalPrice
) => {
  return axios.post(`${serverUrl}/api/shopify/calculate-order`, {
    values,
    lineItems,
    customer,
    shipping,
    discountApplications,
    lineItemTotalPrice,
    subtotalPrice,
  })
}
export const completeShopifyOrder = id => {
  return axios.post(`${serverUrl}/api/shopify/complete-order`, { id })
}

export const createShopifyCustomer = (email, password) => {
  const query = `
    mutation {
      customerCreate(input: {
        email: "${email}",
        password: "${password}"
      }) {
        userErrors {
          field
          message
        }
        customer {
          id
        }
      }
    }`
  return axios.post(
    'https://staykept.myshopify.com/api/2019-10/graphql.json',
    { query },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': '664bd2414643632b3b0eab35a7a13463',
      },
    }
  )
}

export const authenticateShopifyCustomer = (email, password) => {
  const query = `
    mutation {
      customerAccessTokenCreate(input: {
        email: "${email}",
        password: "${password}"
      }) {
        userErrors {
          field
          message
        }
        customerAccessToken {
          accessToken
          expiresAt
        }
      }
    }`
  return axios.post(
    'https://staykept.myshopify.com/api/2019-10/graphql.json',
    { query },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': '664bd2414643632b3b0eab35a7a13463',
      },
    }
  )
}

export const getShopifyCustomer = accessToken => {
  const query = `
    query {
      customer(customerAccessToken: "${accessToken}") {
        defaultAddress {
          address1
          address2
          city
          countryCodeV2
          provinceCode
          firstName
          lastName
          phone
          zip
        }
        id
        email
      }
    }`
  return axios.post(
    'https://staykept.myshopify.com/api/2019-10/graphql.json',
    { query },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': '664bd2414643632b3b0eab35a7a13463',
      },
    }
  )
}

export const getCustomerSubscriptions = id => {
  return axios.get(`${serverUrl}/api/stripe/customer-subscriptions/${id}`)
}

export const cancelSubscription = id => {
  return axios.delete(`${serverUrl}/api/stripe/customer-subscriptions/${id}`)
}

export const saveAddress = async (accessToken, values) => {
  const query = `
    mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
      customerAddressCreate(customerAccessToken: $customerAccessToken, address: $address) {
        customerAddress {
          id
        }
        customerUserErrors {
          code
          field
          message
        }
      }
    }
`
  const variables = {
    customerAccessToken: accessToken,
    address: values,
  }
  const addedAddress = await axios.post(
    'https://staykept.myshopify.com/api/2019-10/graphql.json',
    { query, variables },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': '664bd2414643632b3b0eab35a7a13463',
      },
    }
  )
  console.log(addedAddress)
  const addressId =
    addedAddress.data.data.customerAddressCreate.customerAddress.id
  console.log(addressId)
  const query2 = `
    mutation customerDefaultAddressUpdate {
      customerDefaultAddressUpdate(customerAccessToken: "${accessToken}", addressId: "${addressId}") {
        customer {
          id
        }
        customerUserErrors {
          code
          field
          message
        }
      }
    }
`
  const savedAddress = await axios.post(
    'https://staykept.myshopify.com/api/2019-10/graphql.json',
    { query: query2 },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': '664bd2414643632b3b0eab35a7a13463',
      },
    }
  )
  console.log(savedAddress)
  return savedAddress
}

export const sendForgotPWEmail = email => {
  const query = `
      mutation {
        customerRecover(email: "${email}") {
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `
  return axios.post(
    'https://staykept.myshopify.com/api/2019-10/graphql.json',
    { query },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': '664bd2414643632b3b0eab35a7a13463',
      },
    }
  )
}

export const resetPassword = (id, resetToken, password) => {
  const query = `mutation customerReset($id: ID!, $input: CustomerResetInput!) {
      customerReset(id: $id, input: $input) {
        customer {
          id
        }
        customerAccessToken {
          accessToken
          expiresAt
        }
        customerUserErrors {
          code
          field
          message
        }
      }
    }`
  const variables = {
    id,
    input: {
      resetToken,
      password,
    },
  }
  return axios.post(
    'https://staykept.myshopify.com/api/2019-10/graphql.json',
    { query, variables },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': '664bd2414643632b3b0eab35a7a13463',
      },
    }
  )
}
