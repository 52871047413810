import React, { useContext } from 'react'
import { createGlobalStyle } from 'styled-components'

import StoreContext from '~/context/StoreContext'
 
const Style = createGlobalStyle`
  body {
    ${({sidebarCartOpen, sidebarMenuOpen}) => sidebarCartOpen || sidebarMenuOpen ? `
      overflow: hidden;
    ` : '' }
  }
`
const GlobalStyle = () => {
  const {
    store: { sidebarMenuOpen, sidebarCartOpen },
  } = useContext(StoreContext)

  return <Style sidebarMenuOpen={sidebarMenuOpen} sidebarCartOpen={sidebarCartOpen} />
  
}
export default GlobalStyle