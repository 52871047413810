import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import {gsap} from 'gsap'
import {CSSPlugin} from 'gsap/CSSPlugin'
import './styles.scss'

import StoreContext from '~/context/StoreContext'

const countQuantity = lineItems => {
  let quantity = 0

  lineItems.forEach(item => {
    quantity = quantity + item.quantity
  })

  return quantity
}

const Navigation = ({ siteTitle }) => {
  const {
    store: { checkout },
    openCart,
  } = useContext(StoreContext)
  const [quantity, setQuantity] = useState(
    countQuantity(checkout ? checkout.lineItems : [])
  )
  useEffect(() => {
    const newQuantity = countQuantity(checkout ? checkout.lineItems : [])
    setQuantity(newQuantity)
  }, [checkout])
  gsap.registerPlugin(CSSPlugin)
  
  useEffect(()=>{
    const links = document.querySelectorAll('[data-nav-animate]');
    let tl = gsap.timeline();
    tl
    .fromTo(links, { css: {y: '-80px'}}, { css: {y: '0px'}, stagger: 0.2, duration: 0.8})
  },[])

  const customer = Cookies.get('KEPT_SESS')

  return (
    <div className="navigation tablet-and-up d-flex">
      <div className="col-6 d-flex left-links">
        <div className="d-flex">
          <Link data-nav-animate className="roboto-txt-sm gradient-text-rotated" to="/shop">
            Shop all
          </Link>
          <Link data-nav-animate className="roboto-txt-sm gradient-text-rotated" to="/blog">
            Blog
          </Link>
        </div>
      </div>
      <div className="col-6 d-flex right-links">
        <div className="d-flex">
          {customer ? (
            <Link data-nav-animate className="roboto-txt-sm gradient-text-rotated" to="/account">
              Account
            </Link>
          ) : (
            <Link data-nav-animate className="roboto-txt-sm gradient-text-rotated" to="/login">
              Login
            </Link>
          )}
          <span data-nav-animate className="roboto-txt-sm cart-click" onClick={openCart}>
            <span className="gradient-text-rotated">Cart</span>{' '}
            <span className="cart-quantity">({quantity})</span>
          </span>
        </div>
      </div>
    </div>
  )
}

Navigation.propTypes = {
  siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
}

export default Navigation
