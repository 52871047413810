import React, { useContext } from 'react'
import { Link } from 'gatsby'
import StoreContext from '~/context/StoreContext'
import { Close, Minus, Plus } from '~/components/.base/icons'

const LineItem = props => {
  const { line_item, index } = props
  const {
    removeLineItem,
    updateLineItem,
    closeCart,
    store: { client, checkout, trueLinePrices },
  } = useContext(StoreContext)
  console.log(line_item)
  const variantImage = line_item.variant.image ? (
    <img
      src={line_item.variant.image.src}
      alt={`${line_item.title} product shot`}
      height="90px"
    />
  ) : null
  const handleRemove = () => {
    removeLineItem(client, checkout.id, line_item.id)
  }
  const handleSubtract = () => {
    if (line_item.quantity === 1) {
      handleRemove()
    } else {
      updateLineItem(client, checkout.id, line_item.id, line_item.quantity - 1)
    }
  }
  const handleAdd = () => {
    updateLineItem(client, checkout.id, line_item.id, line_item.quantity + 1)
  }
  return trueLinePrices.length && trueLinePrices[index] ? (
    <div className="lineItem">
      <div className="lineItem__header row">
        <Link
          className="col"
          onClick={closeCart}
          to={`/product/${line_item.title
            .toLowerCase()
            .split(' ')
            .join('-')}`}
        >
          <h4 className="lineItem__title u-h4">
            {line_item.title}
            {`  `}
            {line_item.variant.title === !'Default Title'
              ? line_item.variant.title
              : ''}
          </h4>
        </Link>
        <button className="col-auto gradient-text lineItem__remove" onClick={handleRemove}><Close /></button>
      </div>
      <div className="row">
        <div className="col-6">
          {variantImage}
        </div>
        <div className="col-6 d-flex flex-column justify-content-between">
          <p className="lineItem__priceList gradient-text">
            {trueLinePrices[index] &&
            trueLinePrices[index].price !== trueLinePrices[index].basePrice ? (
              <span>
                <strike>${trueLinePrices[index].basePrice.toFixed(2)}</strike> ${trueLinePrices[index].price.toFixed(2)}
              </span>
            ) : (
              <span>
                ${trueLinePrices[index].price.toFixed(2)}
              </span>
            )}
          </p>
          <div className="quantityWrapper d-flex align-center">
            <button className="gradient-text" onClick={handleSubtract}><Minus /></button>
            <span className="quantity flex-grow-1 tc">{line_item.quantity}</span>
            <button className="gradient-text" onClick={handleAdd}><Plus /></button>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default LineItem
