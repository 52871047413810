import React, { useState, useEffect } from 'react'
import Client from 'shopify-buy'

import Context from '~/context/StoreContext'
import { updateAddressForTaxes } from '../helpers/requests'

const client = Client.buildClient({
  storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN,
  domain: `${process.env.SHOP_NAME}.myshopify.com`,
})

const ContextProvider = ({ children }) => {
  let initialStoreState = {
    client,
    adding: false,
    checkout: { lineItems: [], appliedDiscount: '' },
    products: [],
    shop: {},
    sidebarCartOpen: false,
    sidebarMenuOpen: false,
    trueLinePrices: [],
  }

  const [store, updateStore] = useState(initialStoreState)
  useEffect(() => {
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== 'undefined'
      const existingCheckoutID = isBrowser
        ? localStorage.getItem('shopify_checkout_id')
        : null

      const setCheckoutInState = checkout => {
        if (isBrowser) {
          localStorage.setItem('shopify_checkout_id', checkout.id)
        }

        updateStore(prevState => {
          return { ...prevState, checkout }
        })
      }

      const createNewCheckout = () => store.client.checkout.create()
      const fetchCheckout = id => store.client.checkout.fetch(id)

      if (existingCheckoutID) {
        try {
          const checkout = await fetchCheckout(existingCheckoutID)
          // Make sure this cart hasn’t already been purchased.
          if (!checkout.completedAt) {
            setCheckoutInState(checkout)
            return
          }
        } catch (e) {
          localStorage.setItem('shopify_checkout_id', null)
        }
      }

      const newCheckout = await createNewCheckout()
      setCheckoutInState(newCheckout)
    }

    initializeCheckout()
  }, [store.client.checkout])


  useEffect(() => {
    updateStore((prevState) => {
      const newLinePrices = prevState.checkout.lineItems.map((line_item) => {
        const isSubscription = !!line_item.customAttributes.find(
          (attr) => attr.key === "subscription_interval"
        );
        const linePrice = Number(line_item.variant.price);
        const { quantity } = line_item;
        if (
          line_item.customAttributes.find(
            (attr) => attr.key === "upsell_product"
          )
        ) {
          const upsellDiscount = Number(
            line_item.customAttributes.find(
              (attr) => attr.key === "upsell_discount"
            ).value
          );
          const discountType = line_item.customAttributes.find(
            (attr) => attr.key === "discount_type"
          ).value;
          if (discountType === "Percent Discount") {
            return {
              price:
                (linePrice - (linePrice * upsellDiscount) / 100) * quantity,
              basePrice: isSubscription
                ? ((linePrice * 10) / 9) * quantity
                : linePrice * quantity,
              unitPrice: linePrice - (linePrice * upsellDiscount) / 100,
              baseUnitPrice: isSubscription ? (linePrice * 10) / 9 : linePrice,
              quantity,
              isSubscription,
              upsellDiscountInEffect: true,
              discountType,
              upsellDiscount,
            };
          } else if (discountType === "Absolute Discount") {
            return {
              price: (linePrice - upsellDiscount) * quantity,
              basePrice: isSubscription
                ? ((linePrice * 10) / 9) * quantity
                : linePrice * quantity,
              unitPrice: linePrice - upsellDiscount,
              baseUnitPrice: isSubscription ? (linePrice * 10) / 9 : linePrice,
              quantity,
              isSubscription,
              upsellDiscountInEffect: true,
              discountType,
              upsellDiscount,
            };
          }
        } else if (
          line_item.customAttributes.find(
            (attr) => attr.key === "quantity_discount_unit_price"
          )
        ) {
          const quantityDiscountUnitPrices = line_item.customAttributes
            .find((attr) => attr.key === "quantity_discount_unit_price")
            .value.split(",");
          const thresholds = line_item.customAttributes
            .find((attr) => attr.key === "quantity_discount_threshold")
            .value.split(",");
          var activeDiscountIndex = 0,
            highestQuantityThreshold = 0,
            discountInEffect = false;
          for (let i = 0; i < thresholds.length; i++) {
            if (
              quantity >= thresholds[i] &&
              thresholds[i] > highestQuantityThreshold
            ) {
              discountInEffect = true;
              activeDiscountIndex = i;
              highestQuantityThreshold = thresholds[i];
            }
          }
          if (discountInEffect) {
            return {
              price: isSubscription
                ? (9 / 10) *
                  quantityDiscountUnitPrices[activeDiscountIndex] *
                  quantity
                : quantityDiscountUnitPrices[activeDiscountIndex] * quantity,
              basePrice: isSubscription
                ? ((linePrice * 10) / 9) * quantity
                : linePrice * quantity,
              unitPrice: isSubscription
                ? (9 / 10) * quantityDiscountUnitPrices[activeDiscountIndex]
                : quantityDiscountUnitPrices[activeDiscountIndex],
              baseUnitPrice: isSubscription ? (linePrice * 10) / 9 : linePrice,
              isSubscription,
              quantity,
              quantityDiscountInEffect: true,
            };
          } else {
            return {
              price: linePrice * quantity,
              basePrice: isSubscription
                ? ((linePrice * 10) / 9) * quantity
                : linePrice * quantity,
              unitPrice: linePrice,
              baseUnitPrice: isSubscription ? (linePrice * 10) / 9 : linePrice,
              quantity,
              isSubscription,
            };
          }
        } else {
          return {
            price: linePrice * quantity,
            basePrice: isSubscription
              ? ((linePrice * 10) / 9) * quantity
              : linePrice * quantity,
            unitPrice: linePrice,
            baseUnitPrice: isSubscription ? (linePrice * 10) / 9 : linePrice,
            quantity,
            isSubscription,
          };
        }
        return true;
      });
      let baseSubtotal = newLinePrices.reduce((a, b) => a + b.price, 0),
        basePricesSubtotal = newLinePrices.reduce((a, b) => a + b.basePrice, 0),
        discounts = 0;
      const { discountApplications } = prevState.checkout;
      if (discountApplications && discountApplications.length) {
        if (discountApplications[0].targetType === "LINE_ITEM") {
          if (discountApplications[0].value.percentage) {
            discounts =
              (baseSubtotal / 100) * discountApplications[0].value.percentage;
          } else if (discountApplications[0].value.amount) {
            discounts = Number(discountApplications[0].value.amount);
          }
        }
      }
      return {
        ...prevState,
        trueLinePrices: newLinePrices,
        trueLineItemsSubtotalNoDiscounts: baseSubtotal,
        trueLineItemsSubtotal: baseSubtotal - discounts,
        trueLineItemsBasePriceSubtotalNoDiscounts: basePricesSubtotal,
      };
    });
  }, [store.checkout.discountApplications, store.checkout.lineItems]);

  return (
    <Context.Provider
      value={{
        store,
        addVariantToCart: (variantId, quantity, customAttributes) => {
          if (variantId === '' || !quantity) {
            return
          }

          updateStore(prevState => {
            return { ...prevState, adding: true }
          })

          const { checkout, client } = store

          const checkoutId = checkout.id
          const lineItemsToUpdate = [
            {
              variantId,
              quantity: parseInt(quantity, 10),
              customAttributes,
            },
          ]
          console.log(lineItemsToUpdate)
          return client.checkout
            .addLineItems(checkoutId, lineItemsToUpdate)
            .then(checkout => {
              updateStore(prevState => {
                return { ...prevState, checkout, adding: false }
              })
            })
        },
        removeLineItem: (client, checkoutID, lineItemID) => {
          return client.checkout
            .removeLineItems(checkoutID, [lineItemID])
            .then(res => {
              updateStore(prevState => {
                return { ...prevState, checkout: res }
              })
            })
        },
        updateLineItem: (client, checkoutID, lineItemID, quantity) => {
          const lineItemsToUpdate = [
            { id: lineItemID, quantity: parseInt(quantity, 10) },
          ]
          return client.checkout
            .updateLineItems(checkoutID, lineItemsToUpdate)
            .then(res => {
              updateStore(prevState => {
                return { ...prevState, checkout: res }
              })
            })
        },
        applyDiscount: (client, checkoutID, discountCode) => {
          console.log(store.checkout)
          return client.checkout
            .addDiscount(checkoutID, discountCode)
            .then(checkout => {
              console.log(checkout)
              updateStore(prevState => {
                return { ...prevState, checkout }
              })
            })
        },
        removeDiscount: (client, checkoutID, discountCode) => {
          client.checkout.removeDiscount(checkoutID).then(checkout => {
            checkout.appliedDiscount = ''
            updateStore(prevState => {
              return { ...prevState, checkout }
            })
          })
        },
        openMenu: val => {
          updateStore(prevState => {
            console.log(prevState.sidebarMenuOpen)
            return { ...prevState, sidebarMenuOpen: !prevState.sidebarMenuOpen }
          })
        },
        closeMenu: val => {
          updateStore(prevState => {
            return { ...prevState, sidebarMenuOpen: false }
          })
        },
        openCart: val => {
          updateStore(prevState => {
            console.log(true)
            return { ...prevState, sidebarCartOpen: true }
          })
        },
        closeCart: val => {
          updateStore(prevState => {
            return { ...prevState, sidebarCartOpen: false }
          })
        },
        stateChangeHandler: newState => {
          updateStore(prevState => {
            return {
              ...prevState,
              sidebarCartOpen: newState.sidebarCartOpen,
              sidebarMenuOpen: newState.sidebarMenuOpen,
            }
          })
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}
export default ContextProvider
