import React, { useContext } from 'react'
import StoreContext from '~/context/StoreContext'
import './styles.scss'
import { Link } from 'gatsby'
import Cookies from 'js-cookie'
import { gsap } from 'gsap'

const SidebarMenuLeft = ({ data }) => {
  const {
    store: { sidebarMenuOpen },
    closeMenu,
    openCart,
    stateChangeHandler,
  } = useContext(StoreContext)
  const customer = Cookies.get('KEPT_SESS')
  const [links, setLinks] = React.useState([])
  React.useEffect(() => {
    let links = Array.prototype.slice.call(
      document.querySelectorAll('.sidebar-drawer-menu a.highlight')
    )
    setLinks(links)
  }, [])

  const menuRef = React.useRef()
  if (sidebarMenuOpen) {
    gsap.to(
      menuRef.current,
      { opacity: 1, zIndex: 80, duration: 0.5, ease: 'power2.out' },
      'intro'
    )
    gsap.fromTo(
      links,
      { opacity: 0, translateY: '20' },
      {
        opacity: 1,
        translateY: '0',
        zIndex: 80,
        duration: 1,
        stagger: 0.2,
        ease: 'power2.out',
      },
      'intro'
    )
  } else {
    gsap.to(menuRef.current, {
      opacity: 0,
      zIndex: -80,
      duration: 0.5,
      ease: 'power2.out',
    })
  }

  return (
    <div
      customBurgerIcon={false}
      className={'sidebar-drawer-menu'}
      ref={menuRef}
    >
      <div className="relative menu-inner d-flex">
        <div className="menu-items d-flex flex-column">
          <div className="desktopRow row align-center mx-0 mt-md-auto">
            <Link className="highlight" onClick={closeMenu} to="/">
              <span className="roboto-txt-sm gradient-text">01</span>Home
            </Link>
            <Link className="desktopOnly" onClick={closeMenu} to="/contact">
              Contact us
            </Link>
          </div>
          <div className="desktopRow row align-center mx-0">
            <Link className="highlight" onClick={closeMenu} to="/shop">
              <span className="roboto-txt-sm gradient-text">02</span>Shop all
            </Link>
            <Link className="highlight" onClick={closeMenu} to="/blog">
              <span className="roboto-txt-sm gradient-text">03</span>Blog
            </Link>

            <div className="desktopOnly">
              {customer ? (
                <Link onClick={closeMenu} to="/account">
                  Account
                </Link>
              ) : (
                <Link onClick={closeMenu} to="/login">
                  Login
                </Link>
              )}
            </div>
          </div>
          <div className="desktopRow row align-center mx-0">
            <ul className="desktopOnly linklist linklist--withDotSeperator">
              <li>
                <Link onClick={closeMenu} to="/products/purify-face-oil">
                  Purify
                </Link>
              </li>
              <li>
                <Link onClick={closeMenu} to="/products/hydrate-face-cream">
                  Hydrate
                </Link>
              </li>
              <li>
                <Link onClick={closeMenu} to="/products/renew-skin-serum">
                  Renew
                </Link>
              </li>
              <li>
                <Link onClick={closeMenu} to="/products/rejuvenate-eye-gel">
                  Rejuvinate
                </Link>
              </li>
            </ul>

            <Link className="highlight" onClick={closeMenu} to="/about">
              <span className="roboto-txt-sm gradient-text">04</span>About
            </Link>
          </div>
          <div className="subMenu mobileOnly mt-auto">
            <div>
              {customer ? (
                <Link onClick={closeMenu} to="/account">
                  Account
                </Link>
              ) : (
                <Link onClick={closeMenu} to="/login">
                  Login
                </Link>
              )}
            </div>
            <div>
              <Link onClick={closeMenu} to="/contact">
                Contact us
              </Link>
            </div>
            <ul className="linklist linklist--withDotSeperator mb-4">
              <li>
                <Link onClick={closeMenu} to="/products/purify-face-oil">
                  Purify
                </Link>
              </li>
              <li>
                <Link onClick={closeMenu} to="/products/hydrate-face-cream">
                  Hydrate
                </Link>
              </li>
              <li>
                <Link onClick={closeMenu} to="/products/renew-skin-serum">
                  Renew
                </Link>
              </li>
              <li>
                <Link onClick={closeMenu} to="/products/rejuvinate-eye-gel">
                  Rejuvinate
                </Link>
              </li>
            </ul>
          </div>
          <div className="bottom desktopRow mt-md-auto">
            <ul>
              <li>
                <Link onClick={closeMenu} to="/terms">Terms &amp; conditions</Link>
              </li>
              <li>
                <Link onClick={closeMenu} to="/privacy-policy">Privacy policy</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SidebarMenuLeft
