import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import ContextProvider from '~/provider/ContextProvider'

import GlobalStyle from '~/theme/globalStyle'

import Navigation from '~/components/Navigation'
import SidebarMenu from '~/components/SidebarMenu/SidebarMenu'
import SidebarCart from '~/components/SidebarCart'
import MobileMenu from '~/components/MobileMenu'
import SidebarMenuLeft from '~/components/SidebarMenuLeft/SidebarMenuLeft'

import StoryblokService from '../utils/storyblok-service'
import '../utils/vendor.min.css'
import '../utils/styles.scss'


const Layout = ({ children, location }) => {
  
  return (
    <ContextProvider>
      <GlobalStyle />
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => {

          return (
            <>
              {/* insert helmet */}
              <Helmet
                script={[
                  {"src": `//app.storyblok.com/f/storyblok-latest.js?t=${StoryblokService.token}`, 
                  "type": "text/javascript"}
                ]}
              />
              <Helmet
                  script={[
                    {
                    "innerHTML": `var StoryblokCacheVersion = '${StoryblokService.getCacheVersion()}';`,
                    "type": "text/javascript"
                    }
                  ]}
              />
              {location.pathname.includes('/checkout') ||
              location.pathname.includes('/order-completed') ? (
                <div className="checkout-body">
                  <SidebarMenu checkout={true} />
                  <MobileMenu />
                </div>
              ) : (
                <Fragment>
                  
                  <Navigation siteTitle={data.site.siteMetadata.title} />
                  <SidebarCart />
                  <SidebarMenuLeft />
                  <SidebarMenu />
                  <MobileMenu />
                </Fragment>
              )}
              <div>
                {children}
                <footer>
                  © {new Date().getFullYear()}, Built with
                  {` `}
                  <a href="https://www.gatsbyjs.org">Conspire</a>
                </footer>
              </div>
            </>
          )
        }}
      />
    </ContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
