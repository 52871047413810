import React from 'react'

export const Close = () => <svg className="icon icon--close" viewBox="0 0 24 24">
    <defs>
        <linearGradient id="cdmsgyf8aa" x1="0%" x2="100%" y1="50%" y2="50%">
            <stop offset="0%" stopColor="#D38448"/>
            <stop offset="100%" stopColor="#855131"/>
        </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
        <g fill="url(#cdmsgyf8aa)" transform="translate(-330 -120)">
            <g>
                <g>
                    <g>
                        <path d="M7.05 5.636l4.95 4.95 4.95-4.95c.39-.39 1.023-.39 1.414 0 .39.39.39 1.024 0 1.414L13.414 12l4.95 4.95c.39.39.39 1.023 0 1.414-.39.39-1.024.39-1.414 0L12 13.414l-4.95 4.95c-.39.39-1.023.39-1.414 0-.39-.39-.39-1.024 0-1.414l4.95-4.95-4.95-4.95c-.39-.39-.39-1.023 0-1.414.39-.39 1.024-.39 1.414 0z" transform="translate(20 116) translate(0 4) translate(310)"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>

export const Plus = () => <svg className="icon icon--plus" width="24" height="24" viewBox="0 0 24 24">
    <defs>
        <linearGradient id="oifqsmgkga" x1="0%" x2="100%" y1="50%" y2="50%">
            <stop offset="0%" stop-color="#D38448"/>
            <stop offset="100%" stop-color="#855131"/>
        </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
        <g fill="url(#oifqsmgkga)" transform="translate(-320 -300)">
            <g>
                <g>
                    <g>
                        <g>
                            <path d="M7.05 5.636l4.95 4.95 4.95-4.95c.39-.39 1.023-.39 1.414 0 .39.39.39 1.024 0 1.414L13.414 12l4.95 4.95c.39.39.39 1.023 0 1.414-.39.39-1.024.39-1.414 0L12 13.414l-4.95 4.95c-.39.39-1.023.39-1.414 0-.39-.39-.39-1.024 0-1.414l4.95-4.95-4.95-4.95c-.39-.39-.39-1.023 0-1.414.39-.39 1.024-.39 1.414 0z" transform="translate(20 116) translate(0 4) translate(187 162) translate(113 18) rotate(45 12 12)"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>

export const Minus = () => <svg className="icon icon--minus" width="24" height="24" viewBox="0 0 24 24">
    <defs>
        <linearGradient id="r91jvu9wea" x1="0%" x2="100%" y1="50%" y2="50%">
            <stop offset="0%" stop-color="#D38448"/>
            <stop offset="100%" stop-color="#855131"/>
        </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
        <g fill="url(#r91jvu9wea)" transform="translate(-217 -300)">
            <g>
                <g>
                    <g>
                        <g>
                            <path d="M5.636 16.95L16.95 5.636c.39-.39 1.023-.39 1.414 0 .39.39.39 1.024 0 1.414L7.05 18.364c-.39.39-1.023.39-1.414 0-.39-.39-.39-1.024 0-1.414z" transform="translate(20 116) translate(0 4) translate(187 162) translate(10 18) rotate(45 12 12)"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>


export const Arrow = () => <svg className="icon icon--arrow" viewBox="0 0 17 11">
    <defs>
        <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="linearGradient-1">
            <stop stop-color="#D38448" offset="0%"></stop>
            <stop stop-color="#855131" offset="100%"></stop>
        </linearGradient>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
        <g transform="translate(-4.000000, -6.000000)" stroke="url(#linearGradient-1)" stroke-width="3">
            <path d="M20.0597195,10.9698701 L20.6000078,11.5060307 L20.0635644,12.034985 L18.5381442,12.0349939 L19.0782992,11.5015001 C19.0782793,11.5015198 19.0782792,11.5015569 19.0819698,11.5051774 L18.5421201,10.9699467 L20.0597195,10.9698701 Z M16.3838997,15.0399239 C16.3782832,15.0316993 16.3724138,15.023585 16.3662909,15.0155913 L15.9450474,15.3382493 L16.3181672,14.9609493 C16.3109035,14.953766 16.3034833,14.9468364 16.2959178,14.9401604 L16.3007685,14.935885 L16.3887219,15.0356737 L16.3838997,15.0399239 Z M16.3650963,7.99686174 C16.3738124,7.98884402 16.3824743,7.98055577 16.3910758,7.9719948 L16.3102139,8.06177123 L16.3650963,7.99686174 Z M13.9155831,9.03502082 L17.4919913,9.035 L16.4354147,11.5997195 L14.1966126,9.37782574 C14.0912495,9.27038388 13.9975467,9.15556899 13.9155831,9.03502082 Z" id="Path"></path>
        </g>
    </g>
</svg>