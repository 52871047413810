import React, { useContext } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { Link } from 'gatsby'
import StoreContext from '~/context/StoreContext'
import LineItem from './LineItem'
import { Close, Arrow } from '~/components/.base/icons'
import cart from '~/images/Cart.png'
import arrow from '~/images/arrow.png'
import './styles.scss'

const SidebarCart = ({ data }) => {
	const {
		store: { checkout, sidebarCartOpen },
		closeCart,
		stateChangeHandler,
	} = useContext(StoreContext)
	const handleCheckout = () => {
		if (line_items.length) {
			window.location.href = '/checkout'
		} else {
			closeCart()
		}
	}
	const line_items = checkout.lineItems.map((line_item, i) => {
		return (
			<LineItem
				index={i}
				key={line_item.id.toString()}
				line_item={line_item}
				closeCart={closeCart}
			/>
		)
	})
	const hasSubscription = checkout.lineItems.filter(
		lineItem => lineItem.customAttributes.length
	)[0]
		? true
		: false
	return (
		<Menu
			customBurgerIcon={false}
			isOpen={sidebarCartOpen}
			className={'sidebar-cart relative'}
			right
		>
			<div className="cart-top">
				<div className="cartHeader">
					<div className="close-icon" onClick={closeCart}>
						<Close />
					</div>
					<h3 className="tc u-h4">Cart</h3>
				</div>
				<div className="border-gradient" />
				<div className="lineItemsContainer">
					{line_items.length ? (
						line_items
					) : (
						<div className="tc">
							<p>You have no items in your cart.</p>
							<Link onClick={closeCart} to="/shop">
								Continue Shopping
							</Link>
						</div>
					)}
				</div>
			</div>
			<div className="cart-bottom">
				<div className="totals">
					<button className="cart__back" onClick={closeCart}>
						<img className="cart__backIcon" src={arrow} alt="Back" />
					</button>
					<div className="border-gradient"></div>
					<a className="checkout-link" href={checkout.webUrl}>
						<button className="checkoutBtn" onClick={handleCheckout}>
							<span className="checkoutBtn__label">
								{line_items.length ? 'Checkout' : 'Close'}
							</span>
							<span className="checkoutBtn__total gradient-text">
								· ${checkout.subtotalPrice}
							</span>
							<img className="checkoutBtn__icon" src={cart} alt="cart" />
						</button>
					</a>
					{/* <div className="total-item">
						<p>Taxes</p>
						<p>$ {checkout.totalTax}</p>
					</div> */}
					{/* <div className="total-item">
						<p>Total</p>
						<p>$ {checkout.totalPrice}</p>
					</div> */}
				</div>
			</div>
		</Menu>
	)
}
export default SidebarCart
